import React from 'react';

const Contact = props => (
  <section id="contact">
    <div className="inner">
      <div className="grid-wrapper">
        <section className="col-3">
          <span className="icon alt fa-envelope"></span>
          <h3>Email</h3>
          <a target="_top" href="mailto:mission@blackcape.io">
            mission@blackcape.io
          </a>
        </section>
        <section className="col-3">
          <span className="icon alt fa-phone"></span>
          <h3>Phone</h3>
          <a href="tel:+17035704195">(703) 570-4195</a>
        </section>
        <section className="col-3">
          <span className="icon alt fa-home"></span>
          <h3>Addresses</h3>
          <a href="https://maps.app.goo.gl/3mesAWvmHzXEjm9r8" target="_blank" rel="noreferrer">
            4075 Wilson Blvd 8th Floor
            <br />
            Arlington, VA 22203
          </a>
          <br />
          <br />
          <a href="https://maps.app.goo.gl/dTYxiJCisQwtjVNx6" target="_blank" rel="noreferrer">
            7719 Wood Hollow Dr
            <br />
            Suite 240
            <br />
            Austin, TX 78731
          </a>
        </section>
        <section className="col-3">
          <span className="icon alt fa-terminal"></span>
          <h3>Careers</h3>
          <ul className="actions vertical">
            <li>
              <a
                href="https://blackcape.applytojob.com/apply/"
                className="button special fit"
                target="_blank"
                rel="noreferrer"
              >
                Apply
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </section>
);

export default Contact;
